import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './components/App';
import AsyncComponent from './components/AsyncComponent';

const Pivot = () => import('./components/pivot/PivotPage');
const Profile = () => import('./components/common/profile/ProfilePage');
const PivotResult = () => import('./components/pivot/PivotResult');
const Checklist = () => import('./components/checklist/ChecklistPage');
const Request = () => import('./components/request/RequestPage');
const Analysis = () => import('./components/analysis/AnalysisPage');
const AnalysisDetail = () => import('./components/analysis/AnalysisDetail');
const SqlExecutor = () => import('./components/administration/sqlExecutor/SqlExecutorPage');
const Sheet = () => import('./components/sheet/SheetPage');
const SheetDetail = () => import('./components/sheet/SheetDetail');
const Comparative = () => import('./components/comparative/ComparativePage');
const Banking = () => import('./components/comparative/banking/BankingPage');
const ComparativeManager = () => import('./components/comparative/ComparativeManager');
const ComparativeResult = () => import('./components/comparative/ComparativeResult');
const SendAlert = () => import('./components/analysis/AnalysisSendEmailPage');

const AccountingDash2 = () => import('./components/accountingDash2/AccountingDashPage');
const AccountingDash2Requests = () => import('./components/accountingDash2/AccountingAnalysisRequestPage');
const AccountingTotals2 = () => import('./components/accountingDash2/AccountingTotalsPage');
const AccountingAnalysis2 = () => import('./components/accountingDash2/AccountingAnalysisPage');
const AccountingAnalysisDetail2 = () => import('./components/accountingDash2/AccountingAnalysisDetailPage');

const Financial = () => import('./components/financial/FinancialPage');
const FinancialTotals = () => import('./components/financial/FinancialTotalsPage');
const FinancialAnalysis = () => import('./components/financial/FinancialAnalysisPage');
const FinancialAnalysisDetail = () => import('./components/financial/FinancialAnalysisDetailPage');
const FinancialCalendar = () => import('./components/financial/FinancialCalendarPage');

const Welcome = () => import('./components/welcome/welcomePage');
const AdmEsocial = () => import('./components/administration/eSocial/AdmEsocialPage');
const Dctf2 = () => import('./components/dctfv2/DctfPage');
const DctfRequest = () => import('./components/dctfv2/DctfRequestPage');
const DctfReport = () => import('./components/dctfv2/DctfReport');

const DctfIrrf = () => import('./components/dctfIrrf/DctfIrrfPage');
const DctfIrrfReport = () => import('./components/dctfIrrf/DctfIrrfReport');

const DctfFgts = () => import('./components/dctfFgts/DctfFgtsPage');
const DctfFgtsReport = () => import('./components/dctfFgts/DctfFgtsReport');

const Dashboardv2 = () => import('./components/dashboardv2/DashboardPage');
const Dashboardv2RequestPage = () => import('./components/dashboardv2/DashboardRequestPage');
const Dashboardv2Dash = () => import('./components/dashboardv2/Dashboard');
const DashTablev2 = () => import('./components/dashboardv2/DashTable');

const MonitorPage = () => import('./components/monitor/MonitorPage');

const GrdRoutes = () => (
  <HashRouter>
    <Suspense fallback={<div>Carregando...</div>}>
      <App>
        <Routes>
          <Route path='/pivot' element={<AsyncComponent importComponent={Pivot} />} />
          <Route path='/pivotResult/:id' element={<AsyncComponent importComponent={PivotResult} />} />
          <Route path='/checklist' element={<AsyncComponent importComponent={Checklist} />} />
          <Route path='/request' element={<AsyncComponent importComponent={Request} />} />
          <Route path='/sheet' element={<AsyncComponent importComponent={Sheet} />} />
          <Route path='/sheetDetail/:id' element={<AsyncComponent importComponent={SheetDetail} />} />
          <Route path='/welcome' element={<AsyncComponent importComponent={Welcome} />} />
          <Route exact path='/analysis' element={<AsyncComponent importComponent={Analysis} showCompanies showFilial />} />
          <Route path='/analysis/:id/request/:idrequest' element={<AsyncComponent importComponent={AnalysisDetail} showCompanies showFilial />} />
          <Route exact path='/comparative' element={<AsyncComponent importComponent={Comparative} />} />
          <Route path='/comparative/:id' element={<AsyncComponent importComponent={ComparativeManager} />} />
          <Route path='/comparativeResult/:id' element={<AsyncComponent importComponent={ComparativeResult} />} />
          <Route exact path='/sendAlert' element={<AsyncComponent importComponent={SendAlert} />} />

          <Route exact path='/banking' element={<AsyncComponent importComponent={Banking} />} />
          <Route path='/banking/:id' element={<AsyncComponent importComponent={ComparativeManager} />} />
          <Route path='/bankingResult/:id' element={<AsyncComponent importComponent={ComparativeResult} />} />

          <Route path='/profile' element={<AsyncComponent importComponent={Profile} />} />

          <Route exact path='/accounting2' element={<AsyncComponent importComponent={AccountingDash2} showCompetence showCompanies />} />
          <Route exact path='/accounting2/requests' element={<AsyncComponent importComponent={AccountingDash2Requests} showCompetence showCompanies />} />
          <Route exact path='/accounting2/:id/company/:idcompany/type/:type' element={<AsyncComponent importComponent={AccountingTotals2} showCompetence showCompanies />} />
          <Route exact path='/accounting2/:idrequest/analysis/:idanalysis/company/:idcompany' element={<AsyncComponent importComponent={AccountingAnalysis2} showCompetence showCompanies />} />
          <Route exact path='/accounting2/:idrequest/analysis/:idanalysis/company/:idcompany/codigo/:codigo' element={<AsyncComponent importComponent={AccountingAnalysisDetail2} showCompetence showCompanies />} />

          <Route exact path='/financial' element={<AsyncComponent importComponent={Financial} showCompetence showCompanies />} />
          <Route exact path='/financial/:id/company/:idcompany' element={<AsyncComponent importComponent={FinancialTotals} />} />
          <Route exact path='/financial/:idrequest/analysis/:idanalysis/company/:idcompany' element={<AsyncComponent importComponent={FinancialAnalysis} showCompetence showCompanies />} />
          <Route exact path='/financial/:idrequest/analysis/:idanalysis/company/:idcompany/codigo/:codigo' element={<AsyncComponent importComponent={FinancialAnalysisDetail} showCompetence showCompanies />} />
          <Route exact path='/financialCalendar/:idrequest/company/:idcompany' element={<AsyncComponent importComponent={FinancialCalendar} showCompetence showCompanies />} />

          <Route exact path='/sqlexecutor' element={<AsyncComponent importComponent={SqlExecutor} />} />
          <Route exact path='/admeSocial' element={<AsyncComponent importComponent={AdmEsocial} />} />
          <Route exact path='/dctf2' element={<AsyncComponent importComponent={Dctf2} showCompetence showCompanies />} />
          <Route exact path='/dctf2/company/:idcompany/year/:year/month/:month/type/:type' element={<AsyncComponent importComponent={DctfReport} showCompetence showCompanies />} />
          <Route exact path='/dctf2/request' element={<AsyncComponent importComponent={DctfRequest} showCompetence />} />

          <Route exact path='/dctfIrrf' element={<AsyncComponent importComponent={DctfIrrf} showCompetence showCompanies />} />
          <Route exact path='/dctfIrrf/company/:idcompany/year/:year/month/:month/type/:type' element={<AsyncComponent importComponent={DctfIrrfReport} showCompetence showCompanies />} />

          <Route exact path='/dctfFgts' element={<AsyncComponent importComponent={DctfFgts} showCompetence showCompanies />} />
          <Route exact path='/dctfFgts/company/:idcompany/year/:year/month/:month/type/:type' element={<AsyncComponent importComponent={DctfFgtsReport} showCompetence showCompanies />} />

          <Route exact path='/dashboardv2' element={<AsyncComponent importComponent={Dashboardv2} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/:id/' element={<AsyncComponent importComponent={Dashboardv2Dash} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/:id/:idQueryRequest' element={<AsyncComponent importComponent={Dashboardv2Dash} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/:id/request' element={<AsyncComponent importComponent={Dashboardv2RequestPage} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/dashtable/:id' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/dashtable/:id/:params/filter/:filterId' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/:id/dashtable/:id' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies showFilial />} />
          <Route exact path='/dashboardv2/:id/dashtable/:id/:params/filter/:filterId' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies showFilial />} />

          <Route exact path='/eSocialv2' element={<AsyncComponent importComponent={Dashboardv2} showCompetence showCompanies />} />
          <Route exact path='/eSocialv2/:id' element={<AsyncComponent importComponent={Dashboardv2Dash} showCompetence showCompanies />} />
          <Route exact path='/eSocialv2/dashtable/:id' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies />} />
          <Route exact path='/eSocialv2/dashtable/:id/:params/filter/:filterId' element={<AsyncComponent importComponent={DashTablev2} showCompetence showCompanies />} />

          <Route exact path='/monitor' element={<AsyncComponent importComponent={MonitorPage} />} />

          <Route path='*' element={<Navigate to='/welcome' />} />
        </Routes>
      </App>
    </Suspense>
  </HashRouter>
);

export default GrdRoutes;
